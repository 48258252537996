<template>
  <div class="upload-box-container">
    <div
      class="drop-area"
      @drop="drop"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @dragover="dragOverArea"
    >
      <div class="drop-area-content" v-bind:class="{ 'on-drop': onDropArea }">
        <span class="drop-text" v-if="onDropArea">
          Drop files here to upload...
        </span>
        <div
          class="upload-box"
          @click="initImgUploadFile"
          ref="drop"
          :style="checkReadonly ? 'cursor: default;' : 'cursor: pointer;'"
        >
          <input
            ref="fileinput"
            type="file"
            id="files"
            name="files"
            style="display: none"
            multiple
            @change="uploadPhoto"
            v-if="!imageDeleting"
            accept="image/*"
          />
          <div class="upload-box-wrapper">
            <img src="@/assets/images/icons/ic_plus_square.svg" class="mb-4" />
            <p class="photo-text mb-0" v-if="isMobile">
              <span style="color: #1f8252">Add photos</span> from your device
            </p>
            <p class="photo-text" v-else>
              Drag and drop your images here, or
              <span style="color: #1f8252">browse</span>
            </p>
            <div class="fs-14 cover-description-text font-weight-thin">
              Supports: jpeg, png, tiff
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhotoUploadBox",
  data() {
    return {
      onDropArea: false,
    };
  },
  props: {
    isMobile: {
      type: Boolean,
      default: true,
    },
    imageDeleting: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 30,
    },
    errImgType: {
      type: Array,
      default: () => [],
    },
    handleDrop: {
      type: Function,
    },
    checkReadonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mobileMediaQuery: function () {
      return window.matchMedia("(min-width: 768px)");
    },
  },
  methods: {
    dragEnter() {
      if (!this.checkReadonly) {
        this.onDropArea = true;
      }
    },
    dragLeave: function (e) {
      if (!e.relatedTarget || !this.$el.contains(e.relatedTarget)) {
        this.onDropArea = false;
        this.doNothing(e);
        this.$refs.drop.style.opacity = 1;
      }
    },
    dragOverArea: function (e) {
      if (!this.checkReadonly) {
        this.doNothing(e);
        this.onDropArea = true;
        this.$refs.drop.style.opacity = 0;
      }
    },
    drop: function (e) {
      this.onDropArea = false;
      this.$refs.drop.style.opacity = 1;
      this.handleDrop(e);
    },
    doNothing(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    uploadPhoto(event) {
      this.$emit("uploadPhoto", event);
    },
    initImgUploadFile() {
      if (!this.checkReadonly) {
        this.$refs.fileinput.click();
      }
    },
  },
};
</script>
<style lang="scss">
.upload-box-container {
  width: 100%;

  .drop-area {
    .drop-area-content {
      position: relative;

      .drop-text {
        position: absolute;
        display: block;
        color: #276ef1;
        font-family: Avenir Next LT W05 Regular !important;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        transform: translateX(-50%);
      }

      &.on-drop {
        background-color: #eff3fe !important;
        border: 2px dashed #276ef1 !important;
        border-radius: 15px;
        z-index: 9999 !important;
      }

      .upload-box {
        width: 100%;
        height: 175px;
        background-color: #eeeeee;
        border: 1px dashed #05b36d;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and(min-width: 768px) {
  .upload-box-container .drop-area .drop-area-content .upload-box {
    height: 250px;
  }
}

@media screen and(min-width: 991px) {
  .upload-box-container .drop-area .drop-area-content .upload-box {
    height: 286px;
  }
}
</style>